import React from "react"
import { Helmet } from "react-helmet"

const EmailSignature = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          backgroundColor: "white",
          maxWidth: "660px",
        }}
      >
        <tr>
          <td>
            <img
              src="https://carecasa.com.au/images/email-signature/logo-with-bg.png"
              alt="logo"
            />
          </td>
          <td style={{ verticalAlign: "top" }}>
            <table>
              <tr>
                <td colSpan="2" style={{ textAlign: "right" }}>
                  <img
                    src="https://carecasa.com.au/images/email-signature/shape-element.png"
                    alt="Shape Element"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%" }} cellSpacing="3">
                    <tr>
                      <td colSpan="2">
                        <span
                          style={{
                            color: "#1C5C4F",
                            fontWeight: 600,
                            fontSize: "20px",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Kaleem Ulah
                        </span>
                        <br />
                        <span
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#25282C",
                          }}
                        >
                          Founder & CEO
                        </span>
                        <div
                          style={{
                            border: "1px solid #1C5C4F",
                            maxWidth: "52px",
                            margin: "5px 0 10px",
                          }}
                        ></div>
                      </td>
                    </tr>
                    {[
                      {
                        icon: "phone-icon.png",
                        href: "tel:+61874271805",
                        text: "(08) 7427 1805",
                      },
                      {
                        icon: "email-icon.png",
                        href: "mailto:kaleem@carecasa.com.au",
                        text: "kaleem@carecasa.com.au",
                      },
                      {
                        icon: "globe-icon.png",
                        href: "https://carecasa.com.au/",
                        text: "www.carecasa.com.au",
                      },
                      {
                        icon: "location-icon.png",
                        href: "https://carecasa.com.au/",
                        text: "315 Prospect Road, Blair Athol, SA 5084",
                      },
                    ].map(({ icon, href, text }, index) => (
                      <tr key={index}>
                        <td
                          style={{ textAlign: "left", verticalAlign: "middle" }}
                        >
                          <img
                            src={`https://carecasa.com.au/images/email-signature/${icon}`}
                            alt={icon}
                            style={{ display: "inline-block" }}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            paddingBottom: "5px",
                            verticalAlign: "middle",
                          }}
                        >
                          <a
                            href={href}
                            style={{
                              color: "#25282C",
                              textDecoration: "none",
                              marginLeft: "5px",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {text}
                            </span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </table>
                </td>
                <td style={{ textAlign: "right", paddingLeft: "30px" }}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <img
                          src="https://carecasa.com.au/images/email-signature/qr-code-1.png"
                          alt="QR Code"
                        />
                        <br />
                        <img
                          src="https://carecasa.com.au/images/email-signature/scan-me-text.png"
                          alt="Scan Me"
                          style={{ marginTop: "10px" }}
                        />
                      </td>
                    </tr>
                  </table>
                  <table style={{ width: "100%" }}>
                    <tr>
                      {[
                        "facebook",
                        "instagram",
                        "twitter",
                        "pinterest",
                        "linkedin",
                      ].map((platform, index) => (
                        <td key={index} style={{ paddingTop: "10px" }}>
                          <a
                            href={`https://www.${platform}.com/carecasa${
                              platform === "x" ? "_au" : ""
                            }`}
                          >
                            <img
                              src={`https://carecasa.com.au/images/email-signature/${platform}-icon.png`}
                              alt={`${platform} Icon`}
                            />
                          </a>
                        </td>
                      ))}
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </>
  )
}

export default EmailSignature
